import React from 'react'
import style from "./Farmon.module.css"
const Farmon = () => {
  return (
    <div className={style.News_Page}>
        <h1>Prezident Farmonlari</h1>
        <div className={style.farmon}>
            <div className={style.muddat}>
                <p style={{color:"blue"}}>125 Band </p>
                <p style={{color:"white"}}> Bajarilish muddati: 29.04.2024 </p>
            </div>
            <h2>Bir oy muddatda milliy reytingda ohirgi o'rinda turgan Andijon iqtisodiyot va qurilish institutining boshkaruv tizimi va o'quv jarayonini samarali tashkil etish buyicha chora-tadbirlar rejasi ishlab chiqib, tasdiqlansin va ijrosini taminlasin.</h2>
     
        </div>
    
   
        <div className={style.farmon}>
            <div className={style.muddat3}>
                <p style={{color:"blue"}}>128 Band </p>
                <p style={{color:"black"}}> 29.05.2024 </p>
            </div>
            
            <h2>Ikki oy muddatda Andijon davlat universiteti qoshida maxsus konsert dasturlarini tashkil etishga mo`ljallanganansambil tashkil etish hamda uni yaratish zarur zamonaviy musiqa asboblari  va jigozlar bilan ta'minlash bo`yicha taklif kiritsin. </h2>
     
        </div>
     
        <div className={style.farmon}>
            <div className={style.muddat4}>
                <p style={{color:"blue"}}>127.1 Band </p>
                <p style={{color:"white"}}> 2024/2025 o`quv yilida </p>
            </div>
            
            <h2>Andijon shahar 2-sonli ichtisoslashtirilgan maktab internati oʻquvchisi Jahongirova Madinaxon Zahriddin qizini 2026/2027 oʻquv yilida Toshkent kimyo-texnologiya instituti bakalavriat bosqichining “kimeviy texnologiya” fakulteti granti asosida oʻqishga qabul qilinishiga, taklif. </h2>
     
        </div>
    
    
      
    
        <div className={style.farmon}>
            <div className={style.muddat}>
                <p style={{color:"blue"}}>129 Band </p>
                <p style={{color:"white"}}> Bajarilish muddati: 29.05.2024 </p>
            </div>
            
            <h2> Ikki oy muddatda Xalqaro tan olingan Quacquarelli Symonds World University Rankings tashkiloti tomonidan elon qilingan oliy talim reytingida birinchi 300 talik ro`yhatga kiritilgan oliy ta`lim muassasalari bakalavriat bosqichi talabalari uchun butun o'qishi davomida faqat a`lo bahoga o'qigan Uzbekiston Respublikasi fuqarosi bo`lgan ohirgi kurs talabalari O`zbekiston Respublikasi presidenti davlat stipendiyalarini berish bo`yicha Vazirlar Mahkamasiga taklif kiritsin. </h2>
     
        </div>
        <div className={style.farmon}>
            <div className={style.muddat3}>
                <p style={{color:"blue"}}>117 Band </p>
                <p style={{color:"black"}}> Bajarilish muddati: 29.06.2024 </p>
            </div>
            
            <h2>  Uch oy muddatda Ixtisoslashtirilgan ta`lim agentliga tizimidagi ixtisoslashtirilgan maktab va maktab internatlarining fan yo'nalishlari bo'yicha oliy ta'lim xodimlari bilan integratsiyani ta'minlash bo`yicha normativ-huquqiy xizhat loyihasini ishlab chiqib, Vazirlar Maxkamasiga kiritsin. </h2>
     
        </div>
       
        <div className={style.farmon}>
            <div className={style.muddat4}>
                <p style={{color:"blue"}}>127.2 Band </p>
                <p style={{color:"white"}}>2026/2027 o`quv yilida  </p>
            </div>
            <h2>Andijon shahar 2-sonli ichtisoslashtirilgan maktab internati oʻquvchisi Zhaongirova Madinaxon Zahriddin qizini 2026/2027 oʻquv yilida Toshkent kimyo-texnologiya instituti bakalavriat bosqichining “kimeviy texnologiya” fakulteti granti asosida oʻqishga qabul qilinishiga, taklif.</h2>
     
        </div>
        <div className={style.farmon}>
            <div className={style.muddat}>
                <p style={{color:"blue"}}>126 Band </p>
                <p style={{color:"white"}}> Bajarilish muddati: 29.05.2024 </p>
            </div>
            
            <h2> Ikki oy muddatda professional ta`lim ishlarida faoliyat yuritib ishlab chiqaruvchi ta`lim ustalarining amaliy ko`nikmalarini oshirish maqsadida Andijon shahar 2-son kasb-hunar maktabida yo`lga qo`yilgan tajribani to`liq o`rganib chiqsin</h2>
     
        </div>
        <div className={style.farmon}>
            <div className={style.muddat3}>
                <p style={{color:"blue"}}>124.1 Band </p>
                <p style={{color:"black"}}> Bajarilish muddati: 01.09.2024 </p>
            </div>
            
            <h2> 2024 yil 1-sentyabrga qadar Asaka agrotexnologiya texnikumi bilan Andizjon qishloq ho`jaligi instituti o'rtasida “2+2” dasturini joriy qilsin. </h2>
     
        </div>
      
       
        <div className={style.farmon}>
            <div className={style.muddat2}>
                <p style={{color:"blue"}}>124.2 Band </p>
                <p style={{color:"white"}}> Bajarilish muddati: 01.03.2024 </p>
            </div>
            
            <h2> Natijasi bo`yicha tizimni qolgan 20 ta texnikum va 12 ta kollegelarda ham joriy qilish bo`yicha Vazirlar Maxkamasiga taklif kiritsin. </h2>
     
        </div>
    </div>
  )
}

export default Farmon