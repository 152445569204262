import React from 'react'
import style from "./Korupsiya.module.css"
import { useTranslation } from "react-i18next";

const Korupsiyaga = () => {
    const {t} =useTranslation()

  return (
    <div className={style.Wrapper}>
        <h1>{t("korupsiya")}</h1>
        <p>Agar sizda Andijon viloyati Oliy ta`lim, fan va innovatsiyalar boshqarmasi, uning quyi tizimidagi tashkilotlarda korrupsion holatlar yuzasidan shikoyat va murojatlar bo`lgan taqdirda boshqarmaning <b style={{color:"blue"}}>+998742281002</b>  raqamlariga murojat qilishingiz so`raladi.</p>
    </div>
  )
}

export default Korupsiyaga